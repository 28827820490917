<template>
  <div class="member-index">
    <el-tabs type="card" v-model="type" @tab-click="handleTabChange">
      <el-tab-pane label="商城订单" name="goods"></el-tab-pane>
      <el-tab-pane label="充值订单" name="vip"></el-tab-pane>
    </el-tabs>
    <div class="search-wrapper">
      <div></div>
      <!-- <el-form inline>
        <el-form-item label="">
          <el-radio v-model="quickTime" :label="15" @change="handleQuickChange(15)">最近15天</el-radio>
          <el-radio v-model="quickTime" :label="90" @change="handleQuickChange(90)">最近三个月</el-radio>
          <el-date-picker
            v-model="pickTime"
            type="month"
            :picker-options="options"
            placeholder="选择查询时间"
            @change="handlePickerChange"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
      </el-form> -->
      <div class="xf-wrapper">
        <!-- <el-input
          placeholder="请输入订单号"
          v-model="searchForm.orderId"
          style="width: 200px;"
          @change="handleSearch"
        ></el-input> -->
        <el-date-picker
          @change="timeChange"
          type="daterange"
          :clearable="false"
          v-model="pickTime"
          :picker-options="pickerOptions"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
    </div>
    <el-table border align="center" :data="list" key="recharge">
      <el-table-column
        label="订单编号"
        align="center"
        prop="OrderNo"
      ></el-table-column>
      <el-table-column
        label="商品名称"
        align="center"
        width="150"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="金额（元）"
        width="150"
        align="center"
        prop="money"
      ></el-table-column>
      <el-table-column
        label="日期"
        width="150"
        align="center"
        prop="CreationTime"
      ></el-table-column>
      <el-table-column label="状态" align="center" width="150" prop="status">
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.state === 'unpaid'
                ? 'primary'
                : scope.row.state === 'success'
                ? 'success'
                : 'info'
            "
            >{{
              scope.row.state === "unpaid"
                ? "待支付"
                : scope.row.state === "success"
                ? "成功"
                : "已取消"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        :width="type === 'goods' ? 180 : 120"
      >
        <template slot-scope="scope">
          <div :class="{ 'a-l': type === 'goods' }">
            <el-button v-if="type === 'goods'" @click="linkTo(scope.row.gid)"
              >查看</el-button
            >
            <el-button
              v-if="
                scope.row.AppealState === null && inTime(scope.row.CreationTime)
              "
              @click="onCreateAppeal(scope.row.id)"
              >申诉</el-button
            >
            <el-button
              v-else-if="scope.row.AppealState === 0"
              @click="onAppeal(scope.row.id, true)"
              type="primary"
              >申诉中</el-button
            >
            <el-button
              type="success"
              v-else-if="scope.row.AppealState === 1"
              @click="onPreview(scope.row.id)"
              >申诉成功</el-button
            >
            <el-button
              v-else-if="scope.row.AppealState === 2"
              @click="onAppeal(scope.row.id, true)"
              >申诉失败</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-table border align="center" :data="list" v-else key="shop">
      <el-table-column label="商品信息" align="center" prop="desc"></el-table-column>
      <el-table-column label="收件人" align="center" width="120" prop="name"></el-table-column>
      <el-table-column label="金额（元）" width="150" align="center" prop="money"></el-table-column>
      <el-table-column label="状态" align="center" width="150" prop="status">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status === '待发货' ? 'warning' : scope.row.status === '已签收' ? 'success' : scope.row.status === '已完成' ? 'info' : 'primary'"
          >{{ scope.row.status }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button size="mini" slot="reference" v-if="scope.row.status === '已签收'">申请退款</el-button>
          <el-button size="mini" slot="reference" v-if="scope.row.status === '已发货'">确认收货</el-button>
          <el-button size="mini" slot="reference" v-if="scope.row.status === '待发货'">催促发货</el-button>
        </template>
      </el-table-column>
    </el-table> -->
    <el-pagination
      :page-size="searchForm.pagenum"
      :current-page.sync="searchForm.page"
      :total="total"
      @current-change="handleCurrentChange"
    ></el-pagination>
    <el-dialog
      :visible.sync="appealVisible"
      title="订单申诉"
      center
      width="700px"
      destroy-on-close
    >
      <el-form
        label-width="90px"
        :rules="appealRules"
        :model="appealForm"
        ref="appealRef"
      >
        <el-form-item label="申诉内容" prop="content">
          <el-input
            placeholder="请输入"
            :maxlength="300"
            show-word-limit
            rows="5"
            type="textarea"
            v-model="appealForm.content"
            :disabled="isPreview"
          ></el-input>
        </el-form-item>
        <el-form-item label="补充图片" prop="">
          <div
            v-for="(file, index) in fileList"
            :class="{ last: index === 2 }"
            :key="index"
            class="list-wrapper"
          >
            <img :src="imagePrefix + file" class="avatar" alt="" />
            <span class="mask" @click="handleRemove(index)">
              <span>
                <i class="el-icon-delete"></i>
              </span>
            </span>
            <!-- <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete">
                <i class="el-icon-download"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span> -->
          </div>
          <el-upload
            v-if="fileList.length < 3 && !isPreview"
            class="avatar-uploader"
            :action="`${apiPrefix}/UploadPic`"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
            accept=".jpg,.png"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <!-- <el-form-item>
          
        </el-form-item> -->
      </el-form>
      <template #footer>
        <el-button type="primary" v-if="!isPreview" @click="confirmAppeal"
          >确定</el-button
        >
        <el-button @click="appealVisible = false">{{
          isPreview ? "关闭" : "取消"
        }}</el-button>
      </template>
    </el-dialog>

    <el-dialog
      :visible.sync="beforeVisible"
      title="请先绑定银行卡号"
      center
      width="450px"
      destroy-on-close
    >
      <el-form
        label-width="85px"
        :rules="beforeRules"
        :model="beforeForm"
        ref="beforeRef"
      >
        <el-form-item label="真实姓名" prop="truename">
          <el-input type="text" v-model="beforeForm.truename"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" prop="bank">
          <el-input type="text" v-model="beforeForm.bank"></el-input>
        </el-form-item>
        <el-form-item label="银行名称" prop="BelongsBank">
          <template #label>
            <span class="custom-label"
              >银行名称<span class="tips-label">所属支行</span></span
            >
          </template>
          <el-input type="text" v-model="beforeForm.BelongsBank"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          
        </el-form-item> -->
      </el-form>
      <template #footer>
        <el-button type="primary" @click="confireBefore">确定</el-button>
        <el-button @click="beforeVisible = false">取消</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderList, addAppeal, editAppeal, setWithdraw } from "@/api";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    let validBank = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入银行卡号"));
      } else if (!/^([1-9]{1})(\d{15}|\d{16}|\d{18})$/.test(value)) {
        callback(new Error("请输入正确的银行卡号!"));
      } else {
        callback();
      }
    };
    return {
      options: {
        disabledDate(val) {
          return val > Date.now();
        },
      },
      pickerOptions: {
        disabledDate(val) {
          return (
            val < moment("2022-01-01 00:00:00").valueOf() ||
            val > moment().endOf("day")
          );
        },
      },
      quickTime: 15,
      pickTime: [moment().subtract(15, "day").valueOf(), moment().valueOf()],
      type: "goods",
      list: [],
      total: 0,
      amount: 0,
      searchForm: {
        searchKey: "",
        page: 1,
        pagenum: 10,
        begin: "",
        end: "",
      },
      appealVisible: false,
      appealRules: {
        content: [
          { required: true, message: "请输入申诉内容", trigger: "blur" },
        ],
      },
      isPreview: false,
      appealForm: {
        oid: "",
        content: "",
        pic1: "",
        pic2: "",
        pic3: "",
      },
      fileList: [],
      beforeVisible: false,
      beforeForm: {
        truename: "",
        bank: "",
        BelongsBank: "",
      },
      beforeRules: {
        truename: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        bank: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
          { validator: validBank, trigger: "blur" },
        ],
        BelongsBank: [
          { required: true, message: "请输入银行名称", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.searchForm.begin = moment()
      .subtract(15, "day")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    this.searchForm.end = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    this.getData();
  },
  computed: {
    ...mapGetters(["userinfo"]),
    apiPrefix() {
      return window.globalConfig.VUE_APP_API_URL;
    },
    imagePrefix() {
      return window.globalConfig.VUE_APP_IMAGE_URL;
    },
  },
  methods: {
    confireBefore() {
      this.$refs.beforeRef.validate((val) => {
        if (val) {
          setWithdraw(this.beforeForm).then(async () => {
            this.$message.success("设置成功");
            this.beforeVisible = false;

            this.appealVisible = true;
            await this.$store.dispatch("getUserInfo");
          });
        } else {
          this.$message.warning("请认真填写内容！");
        }
      });
    },
    handleRemove(index) {
      this.fileList.splice(index, 1);
    },
    handleAvatarSuccess(res) {
      console.log("res >>", res);
      this.fileList.push(res.data.url);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("补充图片只能是 JPG 或 png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("补充图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    confirmAppeal() {
      this.$refs.appealRef.validate((val) => {
        if (val) {
          const func = this.isEdit ? editAppeal : addAppeal;
          func({
            ...this.appealForm,
            pic1: this.fileList[0] || "",
            pic2: this.fileList[1] || "",
            pic3: this.fileList[2] || "",
          }).then(() => {
            this.$message.success("提交成功");
            this.appealVisible = false;
            this.getData();
          });
        } else {
          this.$message.warning("请认真填写内容！");
        }
      });
    },
    inTime(time) {
      return moment(time).valueOf() >= moment().subtract(3, "day").valueOf();
    },
    timeChange(val) {
      // console.log("val >>", val);
      this.searchForm.begin = moment(val[0]).format("YYYY-MM-DD HH:mm:ss");
      this.searchForm.end = moment(val[1]).format("YYYY-MM-DD HH:mm:ss");
      this.searchForm.page = 1;
      this.getData();
    },
    async onPreview(id) {
      const res = await editAppeal({
        oid: id,
        content: "",
      });
      this.appealForm = {
        oid: res.oid,
        content: res.content,
        pic1: res.pic1,
        pic2: res.pic2,
        pic3: res.pic3,
      };
      this.fileList = [res.pic1, res.pic2, res.pic3].filter((item) => item);

      this.isEdit = false;
      this.appealVisible = true;
      this.isPreview = true;
    },
    onCreateAppeal(id) {
      this.isPreview = false;
      this.appealForm = {
        oid: id,
        content: "",
        pic1: "",
        pic2: "",
        pic3: "",
      };
      this.fileList = [];
      this.isEdit = false;
      if (this.userinfo.truename) {
        this.appealVisible = true;
      } else {
        this.beforeForm = {
          truename: "",
          bank: "",
          BelongsBank: "",
        };
        this.beforeVisible = true;
      }
    },
    async onAppeal(id, type) {
      this.isPreview = false;

      if (!type) {
        this.appealForm = {
          oid: id,
          content: "",
          pic1: "",
          pic2: "",
          pic3: "",
        };
        this.fileList = [];
        this.appealVisible = true;
        this.isEdit = false;
      } else {
        const res = await editAppeal({
          oid: id,
          content: "",
        });
        this.appealForm = {
          oid: res.oid,
          content: res.content,
          pic1: res.pic1,
          pic2: res.pic2,
          pic3: res.pic3,
        };
        this.fileList = [res.pic1, res.pic2, res.pic3].filter((item) => item);
        this.appealVisible = true;
        this.isEdit = true;
      }
    },
    linkTo(id) {
      let routeData = this.$router.resolve({
        path: "/detail",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async getData() {
      const type = this.type;
      const res = await getOrderList({
        ...this.searchForm,
        type: this.type,
      });
      if (type !== this.type) return;
      this.list = res.data;
      // this.total = res.count.total;
      this.total = res.count;
    },
    handleQuickChange(day) {
      this.pickTime = "";
      this.searchForm.begin = moment()
        .subtract(day, "day")
        .startOf("day")
        .valueOf();
      this.searchForm.end = moment().valueOf();
      this.searchForm.page = 1;
      this.getData();
    },
    handlePickerChange(val) {
      this.quickTime = "";
      this.searchForm.begin = moment(val).startOf("month").valueOf();
      this.searchForm.end = moment(val).endOf("month").valueOf();
      this.searchForm.page = 1;
      this.getData();
    },
    handleSearch() {
      this.searchForm.page = 1;
      this.getData();
    },
    handleTabChange(tab) {
      this.type = tab.name;
      this.amount = 0;
      this.quickTime = 15;
      this.pickTime = [
        moment().subtract(15, "day").valueOf(),
        moment().valueOf(),
      ];
      this.list = [];
      this.searchForm = {
        searchKey: "",
        page: 1,
        pagenum: 10,
        begin: moment()
          .subtract(15, "day")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      };
      this.getData();
    },
    handleCurrentChange(page) {
      this.searchForm.page = page;
      console.log("page >>", page);
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.member-index {
  padding: 10px 20px;
  background: #fff;
  min-height: 561px;
  ::v-deep {
    .el-form-item--small.el-form-item {
      // margin-bottom: 9px;
    }
    .el-radio__input {
      display: none;
    }
  }
  .custom-select {
    width: 120px;
    margin: 0 6px;
  }
  .search-wrapper {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep {
    .el-pagination {
      text-align: right;
      padding: 10px 0;
    }
  }
  .xf-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .a-l {
    text-align: left;
  }
  ::v-deep .avatar-uploader {
    display: inline-block;
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  // .avatar {
  //   width: 178px;
  //   height: 178px;
  //   display: block;
  // }
  .list-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 178px;
    height: 178px;
    margin-right: 10px;
    position: relative;
    &.last {
      margin: 0;
    }
    img {
      width: 100%;
    }
    .mask {
      position: absolute;
      transition: all 0.3s ease;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.3);
      cursor: pointer;
      opacity: 0;

      span {
        font-size: 24px;
        color: #fff;
      }
    }
    &:hover {
      .mask {
        opacity: 1;
      }
    }
  }
  .custom-label {
    position: relative;
  }
  .tips-label {
    position: absolute;
    left: 0px;
    top: 10px;
    font-size: 12px;
    color: #999;
  }
  // .list-wrapper ~ .list-wrapper {
  //   margin-left: 10px;
  // }
}
</style>
